import React from "react";
import "./about.css";
const about = () => {
  return (
    <div id="about" className="about">
      <div className="text">
        <h1 className="h1-text">
          About <a>ME</a>
        </h1>
        <p className="p-text">
          <p style={{ fontSize: "0.8em" }}>
            <br />
            <br />I am a motivated and versatile individual, always eager to
            take on new challenges. With a passion for learning I am dedicated
            to delivering high-quality results. With a positive attitude and a
            growth mindset, I am ready to make a meaningful contribution and
            achieve great things.
          </p>
          <br /> Designing and developing is my passion. So let's portray
          <br /> eachother's skills together. Lets grow together
        </p>
      </div>
    </div>
  );
};

export default about;

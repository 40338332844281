import JiwanKosh from "../assests/JiwanKosh.jpeg";
import mynotes from "../assests/myNotes.jpg";
import onlinestore from "../assests/onlinestore.jpg";

export const projectdata = [
  {
    id: 1,
    image: JiwanKosh,
    projectTitle: "Jiwan Kosh - A fund raising website",
    link: "https://jiwankosh.netlify.app/",
  },
  {
    id: 2,
    image: onlinestore,
    projectTitle: "OnlineStore",
    link: "https://onlinestore1222.netlify.app",
  },
  {
    id: 3,
    image: mynotes,
    projectTitle: "CRUD Notes",
    link: "https://curdnotes.netlify.app/",
  },
  /*{
    id: 4,
    image: Img1,
    projectTitle: "Calculator",
    link: "www",
  },*/
];
